<template>
  <q-card style="margin: 10px">
    <q-card-section>
      <div class="row q-col-gutter-md">
        <div class="col-12">
          <q-skeleton type="text" width="25%" />
        </div>
        <div class="col-2">
          <q-skeleton type="text" width="80%" />
        </div>
        <div class="col-10">
          <q-skeleton type="rect" />
        </div>
        <div class="col-2">
          <q-skeleton type="text" width="60%" />
        </div>
        <div class="col-10">
          <q-skeleton type="rect" />
        </div>
        <div class="col-2">
          <q-skeleton type="text" width="50%" />
        </div>
        <div class="col-10">
          <q-skeleton type="rect" height="150px" />
        </div>
        <div class="col-2">
          <q-skeleton type="text" width="70%" />
        </div>
        <div class="col-10">
          <q-skeleton type="rect" />
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "ProFormSkeleton",
};
</script>
