<template>
  <div>
    <div v-show="loading || loadingFormData || loadingError">
      <pro-form-skeleton />
    </div>
    <div v-show="!loading && !loadingFormData && !loadingError">
      <FormRender
        class="fb-form-container"
        ref="f"
        :data="formDef"
        :value="formRenderData"
        :request="request"
        :fileUploader="larkUploadFile"
        id="formBuilderContent"
        :key="formKey"
        :autoLoadResources="false"
        @showSubmitButton="this.showSubmitButton"
      ></FormRender>

      <div
        v-if="isAllowSubmit"
        class="row justify-end"
        style="padding-right: 10px; padding-bottom: 10px"
      >
        <q-btn
          v-if="isShowBackButton"
          no-caps
          outline
          color="primary"
          :label="getRes('Form.Action.Previous')"
          :disable="submitting || loadingError"
          @click="stepperAction('previous')"
        />

        <q-btn
          v-if="isShowNextButton"
          no-caps
          style="margin-left: 10px"
          color="primary"
          :label="getRes('Form.Action.Next')"
          :disable="submitting || loadingError"
          @click="stepperAction('next')"
        />

        <q-btn
          style="margin-left: 10px"
          no-caps
          v-if="isShowSubmitButton"
          color="primary"
          :label="getRes('save')"
          :disable="submitting || loadingError"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { getLanguage } from "@/util";
import ProFormSkeleton from "@/components/PROSmart/Skeleton/ProFormSkeleton";
// import pdf from "@/mixins/elementsToPDFMixin";
import formStepperActionMixin from "@/mixins/formStepperActionMixin";
import domToPDFMixin from "@/mixins/domToPDFMixin";

export default {
  name: "ProFormRender",
  components: { ProFormSkeleton },
  mixins: [formStepperActionMixin, domToPDFMixin],
  props: {
    mode: {
      type: String,
      required: true,
      validator: (value) => ["Edit", "View"].indexOf(value) !== -1,
    },
    code: {
      type: String,
      required: true,
    },
    formDataPromise: Promise,
    customResourcePromise: Promise,
  },
  data() {
    return {
      formDef: {},
      formData: {},
      formRenderData: {},
      request: { lark: this.invokeService.bind(this) },
      fileListModels: [],
      processModelId: "",
      workflowCode: "",
      formCode: "",
      formKey: 0,
      loading: true,
      loadingFormData: false,
      loadingFormDefError: false,
      loadingFormDataError: false,
      loadingCustomResource: false,
      loadingCustomResourceError: false,
      submitting: false,
      globalResources: "",
      customResource: {},
      isShowSubmitButton: false,
    };
  },
  computed: {
    isAllowSubmit() {
      return this.mode === "Edit" && !this.loading && !this.loadingFormData;
    },
    loadingError() {
      return (
        this.loadingFormDefError ||
        this.loadingFormDataError ||
        this.loadingCustomResourceError
      );
    },
  },
  beforeDestroy() {
    this.formDef = {};
  },
  created() {
    this._ebus = new Vue();
  },
  async mounted() {
    this.globalResources = await this.getFormRender().loadGlobalResources();

    this.$watch(() => [this.mode, this.code], this.loadFormDefinition, {
      immediate: true,
    });
    this.$watch("formDataPromise", this.handleLoadFormData, {
      immediate: true,
    });
    this.$watch("customResourcePromise", this.handleLoadCustomResource, {
      immediate: true,
    });
  },
  methods: {
    /** @returns {formRender} */
    getFormRender() {
      return this.$refs.f;
    },
    larkUploadFile(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        var picName = file.file.name;
        reader.readAsDataURL(file.file);
        reader.onload = function () {
          var cFileContent = this.result;
          // Picture Compression Start
          let maxLength = 1024;
          if (cFileContent === "data:") {
            cFileContent += "text/plain;base64,IA==";
          }
          let hasResult =
            this.result && this.result.split(",")[0].match(/:(.*?);/);
          let mime = hasResult && hasResult[1];
          if (mime && mime.indexOf("image") > -1) {
            let image = new Image();
            image.src = this.result;
            image.onload = function () {
              if (this.width > maxLength || this.height > maxLength) {
                let ratio = this.height / this.width;
                let scale = maxLength / (ratio > 1 ? this.height : this.width);
                let canvas = document.createElement("canvas");
                canvas.height = this.height * scale;
                canvas.width = this.width * scale;
                let ctx = canvas.getContext("2d");
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                //get base64
                cFileContent = canvas.toDataURL(mime);
              }
              cFileContent = cFileContent.substring(
                cFileContent.indexOf("base64,") + 7
              );
              resolve({ content: cFileContent, name: picName });
            };
          } else {
            cFileContent = cFileContent.substring(
              cFileContent.indexOf("base64,") + 7
            );
            resolve({ content: cFileContent, name: picName });
          }
          // Picture Compression end
        };
      }).then(({ content, name }) => {
        return new Promise((resolve, reject) => {
          this.invokeService(
            "CommonBinary",
            "UploadBase64Binary",
            [name, content, false],
            (msg) => {
              if (msg.ReturnData.$ != null) {
                let imageSuffix = ["BMP", "GIF", "JPG", "JPEG", "PNG"];
                let fileName = msg.ReturnData.$.FileName;
                let reg = /\.(\w+)$/;
                let r = reg.exec(fileName);
                let fileSuffix = r && r[1];
                fileSuffix = fileSuffix && fileSuffix.toUpperCase();
                let isImage = fileSuffix
                  ? imageSuffix.indexOf(fileSuffix) > -1
                  : false;
                //Key name, isImage, url
                resolve({
                  fileId: msg.ReturnData.$.ID,
                  fileName: fileName,
                  fileUrl: "",
                  name: fileName,
                  isImage,
                  url: `${window.Lark.ServiceAjax.url}/Handlers/CommonBinary.ashx?dataID=${msg.ReturnData.$.ID}&download=true`,
                });
              } else {
                reject(
                  "upload Error: CommonBinary.UploadBase64Binary. The result is false"
                );
              }
            },
            reject
          );
        });
      });
    },
    getData() {
      return this.getFormRender().getFinallyModels();
    },
    setState(readOnly) {
      if (readOnly) {
        let widgetDict = this.getFormRender().context.widgetDict;
        for (let WID in widgetDict) {
          widgetDict[WID].options.disabled = true;
          widgetDict[WID].options.readonly = true;

          widgetDict[WID].options.inheritDisabled = false;
          widgetDict[WID].options.inheritedOptions = {
            isDisabled: false,
          };
        }
      }
    },
    loadFormDefinition() {
      this.auditInfo = {
        uid: "",
        processid: "",
        activityid: "",
        formname: "",
        pdfpath: "",
        modeljson: "",
        userid: "",
        status: "StartProcess",
      };
      this.isShowSubmitButton = false;

      if (this.mode && this.code) {
        this.loading = true;
        this.loadingFormDefError = false;
        this.formDef = {};

        let loadFormDefPromise = null;

        switch (this.mode) {
          case "Edit": {
            if (!this.code) {
              loadFormDefPromise = Promise.reject("Invalid process code");
            } else {
              this.workflowCode = this.code;
              this.formCode = "";

              console.log(
                "%c%s Workflow Code",
                "color: #c16718;font-weight: bold;",
                "[ProSmart]",
                this.workflowCode
              );

              const userId = this.LocalUser.get("UserId");
              loadFormDefPromise = this.$proSmart.workFlow
                .getLatestProcessModelId(this, this.workflowCode)
                .then((processModel) => {
                  if (!processModel || !processModel.id) {
                    return Promise.reject("Invalid process code");
                  }

                  this.processModelId = processModel.id;
                  return this.$proSmart.workFlow.getStartForm(
                    this,
                    processModel.id,
                    userId,
                    this.workflowCode
                  );
                })
                .then((returnData) => {
                  this.formCode = returnData.outputFormName;

                  console.log(
                    "%c%s Form Code",
                    "color: #c16718;font-weight: bold;",
                    "[ProSmart]",
                    this.formCode
                  );

                  return returnData.formDefinition;
                });
            }
            break;
          }
          case "View": {
            if (!this.code) {
              loadFormDefPromise = Promise.reject("Invalid form code");
            } else {
              this.workflowCode = "";
              this.formCode = this.code;

              console.log(
                "%c%s Form Code",
                "color: #c16718;font-weight: bold;",
                "[ProSmart]",
                this.formCode
              );

              loadFormDefPromise = this.$proSmart.workFlow.getFormDefinitionByCode(
                this,
                this.formCode
              );
            }
            break;
          }
          default:
            loadFormDefPromise = Promise.reject("Invalid mode");
        }

        loadFormDefPromise
          .then((formDef) => {
            if (!formDef) {
              return Promise.reject("Load form def error");
            }

            this.formDef = JSON.parse(formDef);
            this.createForm();
          })
          .catch((err) => {
            console.error(err);
            if (!this.loadingError) {
              this.promptLoadFormError();
            }
            this.loadingFormDefError = true;
          });
      }
    },
    handleLoadFormData() {
      this.loadingFormDataError = false;
      this.formData = {};

      if (this.formDataPromise) {
        this.loadingFormData = true;

        this.formDataPromise
          .finally(() => (this.loadingFormData = false))
          .then((formData) => {
            this.formData = formData;
            this.createForm();
          })
          .catch((err) => {
            console.error(err);
            if (!this.loadingError) {
              this.promptLoadFormError();
            }
            this.loadingFormDataError = true;
          });
      }
    },
    handleLoadCustomResource() {
      this.loadingCustomResourceError = false;
      this.customResource = {};

      if (this.customResourcePromise) {
        this.loadingCustomResource = true;

        this.customResourcePromise
          .finally(() => (this.loadingCustomResource = false))
          .then((customResource) => {
            this.customResource = customResource;
            this.createForm();
          })
          .catch((err) => {
            console.error(err);
            if (!this.loadingError) {
              this.promptLoadFormError();
            }
            this.loadingCustomResourceError = true;
          });
      }
    },
    createForm() {
      if (
        !this.loadingFormData &&
        !this.loadingCustomResource &&
        Object.keys(this.formDef).length > 0
      ) {
        this.formRenderData = Object.assign({}, this.formData, {
          LocalUser: this.LocalUser,
        });
        if (this.mode === "Edit")
          this.formRenderData.workflowCode = this.workflowCode;

        this.formKey += 1; //force re-render
        this.$nextTick()
          .then(() => {
            let formRender = this.getFormRender();

            formRender.setGlobalResources(this.mergeModuleToGlobalResources());
            formRender.setLanguage(getLanguage());
            formRender.formCreate().then(() => {
              this.setState(this.mode === "View");
              this._ebus.$emit("form-created");
              this.loading = false;
              this.updateStepper();
            });
          })
          .then(() => {});
      }
    },
    promptLoadFormError() {
      this.$alert(
        this.getRes("ErrorMessage.LoadFormError"),
        this.getRes("WorkflowOperation.Msg_failure1"),
        { confirmButtonText: this.getRes("ok") }
      );
    },
    onGetContextData: function () {
      // let models = this.$refs.f.getContextModels();
      // let formName = this.$refs.f.getFormName();
      // let renderElementId = "formBuilderContent";

      //TODO: remove tenderId
      const { tenderId } = this.formData;

      let additionalData = {};
      if (tenderId) additionalData.tenderId = tenderId;

      return {
        models: this.getFormRender().getFullContextModels(),
        formName: this.formCode,
        renderElementId: "formBuilderContent",
        path: tenderId ? "" + tenderId : "",
        additionalData: additionalData,
      };
    },
    // saveContextModelsInfo() {
    //   return new Promise((res) => {
    //     Promise.resolve(this.onGetContextData()).then((dataset) => {
    //       var lcPath = dataset.path;
    //
    //       let { LocalUser, ...filterModels } = dataset.models;
    //       filterModels.UserId = LocalUser.UserId;
    //       filterModels.LoginUserId = LocalUser.LoginUserId;
    //       filterModels.EmpCode = LocalUser.EmpCode;
    //       filterModels.EName = LocalUser.EName;
    //       filterModels.CName = LocalUser.CName;
    //       filterModels.TName = LocalUser.TName;
    //       filterModels.timeStamp = new Date().toString();
    //
    //       let canvasList = [
    //         this.renderHtmlToCanvas(dataset.renderElementId),
    //         this.renderObjectToCanvas(filterModels),
    //       ];
    //
    //       if (typeof lcPath == "undefined" || lcPath == null) {
    //         lcPath = "";
    //       }
    //
    //       if (lcPath !== "") {
    //         if (lcPath.substring(0, 1) !== "/") {
    //           lcPath = "/" + lcPath;
    //         }
    //
    //         if (lcPath.substring(lcPath.length - 1, lcPath.length) === "/") {
    //           lcPath = lcPath.substring(0, lcPath.length - 1);
    //         }
    //       }
    //
    //       Promise.all(canvasList).then((canvasList) => {
    //         this.canvasListToPDF(canvasList).then((data) => {
    //           let timeStamp = Date.now();
    //           let randomBit = Math.floor(Math.random() * 9999);
    //           let fileName = "Form_" + timeStamp + "_" + randomBit + ".pdf";
    //
    //           let uploadparams = {
    //             foldername: "ContextModelsPDF" + lcPath,
    //             filename: fileName,
    //             base64content: data.content,
    //           };
    //           this.invokeService(
    //             "Audit",
    //             "UploadAuditPDF",
    //             [uploadparams],
    //             (msg) => {
    //               let result = msg.ReturnData.$;
    //               if (result.Success) {
    //                 //fill in params
    //                 this.auditInfo.processid = "";
    //                 this.auditInfo.activityid = "";
    //                 this.auditInfo.formname = dataset.formName;
    //                 this.auditInfo.pdfpath = result.FilePath;
    //                 this.auditInfo.modeljson = JSON.stringify(filterModels);
    //                 this.auditInfo.userid = LocalUser.UserId;
    //                 this.auditInfo.additionalData = dataset.additionalData;
    //                 this.auditInfo.contextmd5 = this.contextToMd5(data.content);
    //                 this.auditInfo.actualid = filterModels.LoginUserId;
    //                 let { ...params } = this.auditInfo;
    //
    //                 this.saveAuditInfoToDB(params).then((returnid) => {
    //                   this.auditInfo.uid = returnid;
    //                   res();
    //                 });
    //               }
    //             },
    //             () => {}
    //           );
    //         });
    //       });
    //     });
    //   });
    // },
    mergeModuleToGlobalResources() {
      let formResources = JSON.parse(JSON.stringify(this.globalResources));

      if (!formResources["en-US"]) formResources["en-US"] = {};
      if (!formResources["zh-Hans"]) formResources["zh-Hans"] = {};
      if (!formResources["zh-Hant"]) formResources["zh-Hant"] = {};

      for (let originalKey in this.customResource) {
        let replaceKey = this.customResource[originalKey];

        formResources["en-US"][
          originalKey
        ] = this.getResourceInI18nOrFormResources(
          formResources,
          "en-US",
          replaceKey
        );

        formResources["zh-Hans"][
          originalKey
        ] = this.getResourceInI18nOrFormResources(
          formResources,
          "zh-Hans",
          replaceKey
        );

        formResources["zh-Hant"][
          originalKey
        ] = this.getResourceInI18nOrFormResources(
          formResources,
          "zh-Hant",
          replaceKey
        );
      }
      return formResources;
    },
    getResourceInI18nOrFormResources(formResources, lang, replaceKey) {
      if (
        formResources &&
        formResources[lang] &&
        formResources[lang][replaceKey]
      ) {
        return formResources[lang][replaceKey];
      }

      return window.formDesigner.i18n.messages[lang][replaceKey];
    },
    submit() {
      this.submitting = true;
      this.showFullScreenMask();
      this.saveDomContextModelsInfo(this.$refs.f, this.onGetContextData()).then(
        (uid) => {
          this.getData()
            .then((params) => {
              params.contextModelUid = uid;

              const userId = this.LocalUser.get("UserId");
              return this.$proSmart.workFlow.createProcess(
                this,
                this.processModelId,
                userId,
                userId,
                params,
                true
              );
            })
            .then((returnData) => {
              this.auditInfo.processid = returnData.processId;

              if (!this.auditInfo.additionalData)
                this.auditInfo.additionalData = {};

              if (returnData.$) {
                this.$emit("pro-form-submit", this.workflowCode);
                this.$alert(
                  this.getRes("WorkflowOperation.OperationSucceed"),
                  this.getRes("notification"),
                  { confirmButtonText: this.getRes("ok") }
                );

                this.auditInfo.additionalData["errorMessage"] = "";
              } else {
                let message =
                  returnData.info ||
                  "Process failed, please contact system administrator.";

                this.auditInfo.additionalData["errorMessage"] = message;

                this.$alert(
                  message,
                  this.getRes("System.Message.Error.SomethingWentWrong"),
                  { confirmButtonText: this.getRes("ok") }
                );
              }

              this.saveAuditInfoToDB(this.auditInfo);
            })
            .finally(() => {
              this.submitting = false;
              this.hideFullScreenMask();
            });
        }
      );
    },
    showSubmitButton(isShow) {
      this.isShowSubmitButton = isShow;
    },
  },
};
</script>

<style lang="scss" scoped>
.fb-form-container {
  flex: auto;
  overflow: auto;
  padding: 10px;

  input:disabled {
    background-color: rgba(0, 0, 0, 0);
  }
}
</style>
