import { jsPDF } from "jspdf";
import domToImage from "@/lib/domToImage.js";

const LIMITATION = 14400;
const DPI = 72;
const PADDING = 10;

export default {
  data() {
    return {
      auditInfo: {
        uid: "",
        processid: "",
        activityid: "",
        formname: "",
        pdfpath: "",
        modeljson: "",
        userid: "",
        status: "StartProcess",
      },
    };
  },
  methods: {
    /**
     * @param {Vue | Element | Vue[] | Element[] | HTMLElement} vueDom
     * @param {Object} context
     */
    async saveDomContextModelsInfo(vueDom, context) {
      let filterModels = this.addLocalUserInContext(context);
      if (vueDom.$el) vueDom = vueDom.$el;
      let formBase64 = await domToImage.toJpeg(vueDom, {
        bgcolor: "#fff",
        cacheBust: true,
        filter: this.filter,
      });

      let contextDom = this.createContextDom(filterModels);

      let contextBase64 = await domToImage.toJpeg(contextDom, {
        bgcolor: "#fff",
        cacheBust: true,
        filter: this.filter,
      });

      this.removeContextDom(contextDom);

      let blobData = await this.base64ToPdf([formBase64, contextBase64]);
      let uid = await this.uploadAuditPdf(blobData, filterModels, context);

      return Promise.resolve(uid);
    },
    addLocalUserInContext(context) {
      let { LocalUser, ...filterModels } = context.models;
      filterModels.UserId = LocalUser.UserId;
      filterModels.LoginUserId = LocalUser.LoginUserId;
      filterModels.EmpCode = LocalUser.EmpCode;
      filterModels.EName = LocalUser.EName;
      filterModels.CName = LocalUser.CName;
      filterModels.TName = LocalUser.TName;
      filterModels.timeStamp = new Date().toString();

      return filterModels;
    },
    async uploadAuditPdf(blobData, filterModels, context) {
      return new Promise((resolve, reject) => {
        let timeStamp = Date.now();
        let randomBit = Math.floor(Math.random() * 9999);
        let fileName = "Form_" + timeStamp + "_" + randomBit + ".pdf";

        let uploadParams = {
          foldername: "ContextModelsPDF/" + context.path,
          filename: fileName,
          base64content: blobData.content,
        };

        this.invokeService(
          "Audit",
          "UploadAuditPDF",
          [uploadParams],
          (msg) => {
            let result = msg.ReturnData.$;
            if (result.Success) {
              //fill in params
              this.auditInfo.processid = "";
              this.auditInfo.activityid = "";
              this.auditInfo.formname = context.formName;
              this.auditInfo.pdfpath = result.FilePath;
              this.auditInfo.modeljson = JSON.stringify(filterModels);
              this.auditInfo.userid = filterModels.UserId;
              this.auditInfo.additionalData = context.additionalData;
              this.auditInfo.contextmd5 = this.contextToMd5(blobData.content);
              this.auditInfo.actualid = filterModels.LoginUserId;
              let { ...params } = this.auditInfo;

              this.saveAuditInfoToDB(params).then((returnid) => {
                this.auditInfo.uid = returnid;
                return resolve(this.auditInfo.uid);
              });
              return;
            }

            return reject(result);
          },
          (error) => {
            console.error(error);
            return reject(error);
          }
        );
      });
    },
    filter(node) {
      return (
        node.nodeName !== "O:P" &&
        node.nodeValue !== "[if !mso]><!-- " &&
        node.nodeValue !== "<![endif]"
      );
    },
    saveAuditInfoToDB(params) {
      return new Promise((resolve, reject) => {
        let me = this;
        me.invokeService(
          "Audit",
          "SaveContextModelsInfo",
          [params],
          (msg) => {
            if (msg.ReturnData.$.Success) {
              resolve(msg.ReturnData.$.Id);
            } else {
              reject("");
            }
          },
          (fail) => {
            console.error(fail);
            reject("");
          }
        );
      });
    },
    contextToMd5(content) {
      return window.hex_md5(content);
    },
    /**
     * @param {Object} filterModels
     * @return {Node}
     */
    createContextDom(filterModels) {
      let div = window.document.createElement("div");

      let pre = window.document.createElement("pre");
      pre.setAttribute(
        "style",
        // "white-space: pre-wrap;letter-spacing:0.1px;padding:10px"
        "white-space: pre-wrap;letter-spacing:0.1px;padding:10px;width: 2300px;font-family: sans-serif;line-height:14px"
      );
      pre.innerText = JSON.stringify(filterModels);

      div.appendChild(pre);

      window.document.body.appendChild(div);

      return div;
    },
    removeContextDom(dom) {
      dom.parentElement.removeChild(dom);
    },
    /**
     * @param base64
     * @return {Promise<{width: number, height: number}>}
     */
    getBase64ImageDimensions(base64) {
      return new Promise(function (resolved) {
        let i = new Image();
        i.onload = () => {
          resolved({ width: i.width, height: i.height });
        };
        i.src = base64;
      });
    },
    /**
     * @param {array<string>}base64List
     */
    async base64ToPdf(base64List) {
      let leftMargin = 0;
      // let rightMargin = 40;
      let topMargin = 0;
      // let bottomMargin = 50;

      let doc;
      //   for (let page = 0; page < base64List.length; page++) {
      //     let base64 = base64List[page];

      //     let { width, height } = await this.getBase64ImageDimensions(base64);
      //     // let height = 500;
      //     // let width = 500;

      //     let orientation = width > height ? "l" : "p";

      //     if (page === 0) {
      //       doc = new jsPDF(orientation, "px", [width, height]);
      //     } else {
      //       doc.addPage([width, height], orientation);
      //     }

      //     doc.addImage(base64, "JPEG", leftMargin, topMargin, width, height);
      //   }

      const pageOptions = (
        await Promise.all(
          base64List.map(async (x) => {
            let { width, height } = await this.getBase64ImageDimensions(x);
            let orientation = width > height ? "l" : "p";
            let base64 = x;

            const limit = LIMITATION - PADDING * 2;
            if (height > limit) {
              const heightArray = [];
              const factor = Math.floor(height / limit);
              heightArray.push(...new Array(factor).fill(limit));

              const remaining = height - factor * limit;

              if (remaining > 0) {
                heightArray.push(remaining);
              }

              const result = await Promise.all(
                heightArray.map(async (h, hi) => {
                  base64 = await this.cropImg(
                    x,
                    0,
                    hi * limit - (hi > 0 ? 2 : 0),
                    width,
                    h + 2
                  );

                  return {
                    base64,
                    width,
                    height: h,
                    orientation,
                  };
                })
              );

              return result;
            } else {
              return [
                {
                  base64: x,
                  width,
                  height,
                  orientation,
                },
              ];
            }
          })
        )
      ).flatMap((x) => x);

      pageOptions.forEach((x) => {
        if (doc == null) {
          doc = new jsPDF(x.orientation, "in", [
            x.width / DPI,
            (x.height + PADDING * 2) / DPI,
          ]);
        } else {
          doc.addPage(
            [x.width / DPI, (x.height + PADDING * 2) / DPI],
            x.orientation
          );
        }

        doc.addImage(
          x.base64,
          "JPEG",
          leftMargin,
          topMargin,
          x.width / DPI,
          (x.height + 2) / DPI
        );
      });

      let pdfblob = doc.output("dataurlstring");
      let base64blobstring = pdfblob.substr(pdfblob.indexOf(",") + 1);
      // let timeStamp = Date.now();
      // let filename = 'Form_' + timeStamp + '.pdf';
      return { content: base64blobstring };
    },
  },

  /**
   * Crop Image
   * @param base64 Image data
   * @param sx Start X
   * @param sy Start Y
   * @param sw Width
   * @param sh Height
   * @returns Cropped Image Data
   */
  cropImg(base64, sx, sy, sw, sh) {
    return new Promise((resolve) => {
      const img = new Image();
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const cw = document.createAttribute("width");
      const ch = document.createAttribute("height");
      cw.nodeValue = sw;
      ch.nodeValue = sh;
      canvas.setAttributeNode(cw);
      canvas.setAttributeNode(ch);

      img.onload = () => {
        ctx?.drawImage(img, sx, sy, sw, sh, 0, 0, sw, sh);
        resolve(canvas.toDataURL("image/jpeg"));
      };
      img.onerror = (err) => {
        console.log(err);
      };
      img.src = base64;
    });
  },
};
